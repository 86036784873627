import {ROLES} from "../../utils/departaments";

export default [
    {
        title: 'inicio',
        route: 'home',
        icon: 'UsersIcon',

    },
    {
        title: 'Pacientes',
        route: 'Patients',
        icon: 'UsersIcon',
        role:ROLES.MEDICAL.concat(ROLES.ADMIN).concat(ROLES.NURSE).concat(ROLES.RECEPTION).concat(ROLES.MEDICAL_COORDINATION).concat(ROLES.CASH_REGISTER)
    },
    {
        title: 'Agenda',
        route: 'AppointmentList',
        icon: 'FolderIcon',
        role:ROLES.MEDICAL.concat(ROLES.ADMIN).concat(ROLES.RECEPTION).concat(ROLES.MEDICAL_COORDINATION)
    },

    {
        title: 'Tratamientos',
        route: 'ListTreatment',
        icon: 'FileTextIcon',
        exclusive:true,
        role:ROLES.ADMIN.concat(ROLES.NURSE).concat(ROLES.MEDICAL).concat(ROLES.MEDICAL_COORDINATION).concat(ROLES.PHARMACY).concat(ROLES.CASH_REGISTER)

    },
    {
        title: 'Salas de Tratamiento',
        route: 'CalendarPlace',
        icon: 'FileTextIcon',
        exclusive:true,
        role:ROLES.ADMIN.concat(ROLES.MEDICAL).concat(ROLES.RECEPTION).concat(ROLES.MEDICAL_COORDINATION).concat(ROLES.NURSE).concat(ROLES.PHARMACY).concat(ROLES.CASH_REGISTER)

    },
    {
        title: 'Información medico',
        route: 'InfoMedical',
        icon: 'FileTextIcon',
        role:ROLES.MEDICAL

    },

    {
        title: 'Etiquetas Medicamentos',
        route: 'MedicationLabels',
        icon: 'FileTextIcon',
        role:ROLES.PHARMACY
    },
    {
        title: 'Inventario',
        route: 'ProductsInventory',
        icon: 'FileTextIcon',
        role:ROLES.PHARMACY
    },
]
